<template lang="pug">
.hamburger(@click="!isActive")
  .hamburger__icon(:class="{ active: isActive }")
    i
    i
    i
</template>

<script lang="ts" setup>
const isActive = defineModel({ type: Boolean, default: false })
</script>

<style scoped lang="scss">
.hamburger {
  display: none;
  align-items: center;
  justify-content: center;
  width: double();
  height: double();
  cursor: pointer;
  position: relative;
}

.hamburger__icon {
  width: 24px;

  i {
    background-color: color(gray3);
    border-radius: 2px;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
  }

  i:nth-child(1) {
    animation: outT 0.8s backwards;
    animation-direction: reverse;
  }

  i:nth-child(2) {
    margin: 4px 0;
    animation: outM 0.8s backwards;
    animation-direction: reverse;
  }

  i:nth-child(3) {
    animation: outBtm 0.8s backwards;
    animation-direction: reverse;
  }

  &.active {
    i:nth-child(1) {
      animation: inT 0.8s forwards;
    }

    i:nth-child(2) {
      animation: inM 0.8s forwards;
    }

    i:nth-child(3) {
      animation: inBtm 0.8s forwards;
    }
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  50% {
    transform: translateY(7px) rotate(0deg);
  }

  100% {
    transform: translateY(7px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  50% {
    transform: translateY(7px) rotate(0deg);
  }

  100% {
    transform: translateY(7px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
    opacity: 1;
  }

  50% {
    transform: translateY(-7px) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(-7px) rotate(135deg);
    opacity: 0;
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
    opacity: 1;
  }

  50% {
    transform: translateY(-7px) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(-7px) rotate(135deg);
    opacity: 0;
  }
}
</style>
