<template lang="pug">
.contact#contact
  .contact__wrapper
    //-
    //- Contact message
    //-
    .contact__message
      h2
        | {{ $t('contact.messagePart1') }}
        span.gradient.margin-left-quarter.margin-right-quarter {{ $t('contact.messagePart2') }}
        | {{ $t('contact.messagePart3') }}

    //-
    //- Contact form
    //-
    .contact__form(v-if="!submitted")
      form(@submit="handleSubmit")
        .contact__form__input
          input(
            v-model="contact.name"
            type="text"
            name="name"
            autocomplete="name"
            :placeholder="$t('contact.name')")
          .contact__form__error(v-if="errors.name") {{ $t('contact.required') }}

        .contact__form__input
          input(
            v-model="contact.email"
            type="email"
            name="email"
            autocomplete="email"
            :placeholder="$t('contact.email')")
          .contact__form__error(v-if="errors.email") {{ $t('contact.required') }}
          .contact__form__error(v-if="errors.emailValid") {{ $t('contact.invalidEmail') }}

        .contact__form__input
          input(
            v-model="contact.message"
            type="text"
            name="message"
            :placeholder="$t('contact.message')")
          .contact__form__error(v-if="errors.message") {{ $t('contact.required') }}

        .contact__form__toggle
          SwitchButton(
            v-model="contact.confirm"
            name="confirm")
            | {{ $t('contact.readAndAgree') }}
            NuxtLinkLocale.margin-left-quarter(to="/privacy") {{ $t('contact.privacyPolicy') }}
            .contact__form__error.no-margin-top.no-padding-left(v-if="errors.confirm") {{ $t('contact.requiredPrivacyPolicy') }}

        .contact__form__action
          button.button(type="submit") {{ $t('contact.send') }}

    //-
    //- Contact submitted View
    //-
    .contact__submitted(v-else)
      h2.gradient.margin-bottom-half {{ $t('contact.thankYou') }}
      p {{ $t('contact.getBack') }}
</template>

<script lang="ts" setup>
const submitted = ref(false);
const contact = reactive({
  name: '',
  email: '',
  message: '',
  confirm: false,
});

const errors = reactive({
  name: false,
  email: false,
  emailValid: false,
  message: false,
  confirm: false,
});

const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const validateRequired = (value: string) => {
  return value.length > 0;
};

const handleSubmit = async (event: Event) => {
  event.preventDefault();

  // Reset errors
  errors.name = false;
  errors.email = false;
  errors.emailValid = false;
  errors.message = false;
  errors.confirm = false;

  // Validate form
  if (!validateRequired(contact.name)) errors.name = true;
  if (!validateRequired(contact.email)) errors.email = true;
  if (!validateEmail(contact.email)) errors.emailValid = true;
  if (!validateRequired(contact.message)) errors.message = true;
  if (!contact.confirm) errors.confirm = true;

  if (Object.values(errors).some((error) => error)) return;

  // Submit form
  const contactUrl = `${globalThis.$portfolio.apiUrl}/contact`
  await useFetch(contactUrl, {
    method: 'POST',
    body: JSON.stringify(contact),
  });
  submitted.value = true;

  // Reset form
  setTimeout(() => {
    contact.name = '';
    contact.email = '';
    contact.message = '';
    contact.confirm = false;

    submitted.value = false;
  }, 3000);
};

</script>

<style lang="scss" scoped>
.contact {
  background-color: $color-background;
}

.contact__wrapper {
  display: flex;
  max-width: $page-width;
  margin: 0 auto;
  justify-content: space-between;
}

.contact__message,
.contact__form,
.contact__submitted {
  padding: quadruple() simple();
}

.contact__message {
  max-width: 30rem;

  h2 {
    margin-bottom: 0;
  }
}

.contact__form,
.contact__submitted {
  flex-grow: 1;
  max-width: 45rem;
  min-width: 30rem;
  margin-right: 0;
  margin-left: auto;
}

.contact__form__input {
  margin-bottom: simple();

  input {
    width: 100%;
    border-radius: $border-radius;
    border-color: color(gray7);
  }
}

.contact__form__toggle {
  margin-bottom: simple();
  display: flex;
  line-height: simple();
}

.contact__form__action {
  margin-top: double();
  text-align: right;
}

.contact__form__error {
  color: color(danger);
  font-size: font-size(small1);
  padding-left: half();
  margin-top: quarter();

  &~.contact__form__error {
    margin-top: 0;
  }
}

.contact__submitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@include media(palm) {
  .contact__wrapper {
    display: flex;
    flex-direction: column;
    padding: double() 0;
  }

  .contact__message,
  .contact__form,
  .contact__submitted {
    padding: double() simple();
    max-width: inherit;
    min-width: inherit;
  }

  .contact__message {
    text-align: center;
  }

  .contact__form {
    margin-left: initial;
  }

}
</style>
